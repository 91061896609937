




















































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import RetryComponent from "./Retry.component";

@Component
export default class Retry extends Mixins(RetryComponent) {}
